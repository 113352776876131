.App1 {
  font-family: sans-serif;
  /* text-align: center; */
  text-align: left;
}

.fordiv {
  text-align: center;
  height: 400px;
  width: 400px;
  background-color: rgb(151, 151, 192);
}

.buttonType {
  appearance: auto;
  font-size: small;
  user-select: none;
  white-space: pre;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  box-sizing: border-box;
  background-color: whitesmoke;
  color: black;
  padding: 1px 6px;
  border-width: 1px;
  border-radius: 2px;
  border-style: outset;
  border-color: buttonborder;
  border-image: initial;
}
