.slick-track {
    margin-left: 0px !important;
}

.list {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    .listTitleHeader {
        background-color: #435270;
        //width: 1152px;
        border-radius: 3px;
        box-shadow: 0px 8px 10px rgb(0 0 0 / 40%);
        padding: 7px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 28px;
        margin-right: 28px;
    }
    .seeAll {
        color: white;
        font-size: 15px;
        font-weight: 500;
        float: right;
        margin-right: 13px;
        cursor: pointer;
        // margin-top: -0;
        margin-bottom: 15px;
    }
    .listTitle {
        color: white;
        font-size: 20px;
        font-weight: 500;
        // margin-left: 50px;
    }
    .wrapper {
        position: relative;
        .container {
            margin-left: 50px;
            display: flex;
            width: max-content;
            margin-top: 10px;
            transform: translate(0px);
            transition: all 1s ease;
            .title {
                color: white;
                margin-left: 10px;
                margin-top: 7px;
                font-size: 13px;
            }
        }
        .sliderArrow {
            width: 50px;
            height: 100%;
            // background-color: rgba(22, 22, 22, 0.5);
            color: white;
            position: absolute;
            z-index: 99;
            top: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            &.left {
                left: 12px;
            }
            &.right {
                right: 0;
            }
        }
    }
}

.video_channel_slider.slick-slider {
    padding-left: 4.5%;
}

// .list {
//     width: 100%;
//     margin-top: 10px;
//     margin-bottom: 10px;
//     .listTitleHeader {
//         background-color: #00677f;
//         width: 1152px;
//         border-radius: 3px;
//         box-shadow: 0px 8px 10px rgb(0 0 0 / 40%);
//         padding: 7px;
//         margin-top: 20px;
//         margin-bottom: 20px;
//         margin-left: 48px;
//     }
//     .seeAll {
//         color: white;
//         font-size: 15px;
//         font-weight: 500;
//         float: right;
//         margin-right: 13px;
//         cursor: pointer;
//         // margin-top: -0;
//         margin-bottom: 15px;
//     }
//     .listTitle {
//         color: white;
//         font-size: 20px;
//         font-weight: 500;
//         // margin-left: 50px;
//     }
//     .wrapper {
//         position: relative;
//         .container {
//             margin-left: 50px;
//             display: flex;
//             width: max-content;
//             margin-top: 10px;
//             transform: translate(0px);
//             transition: all 1s ease;
//             .title {
//                 color: white;
//                 margin-left: 10px;
//                 margin-top: 7px;
//                 font-size: 13px;
//             }
//         }
//         .sliderArrow {
//             width: 50px;
//             height: 100%;
//             // background-color: rgba(22, 22, 22, 0.5);
//             color: white;
//             position: absolute;
//             z-index: 99;
//             top: 0;
//             bottom: 0;
//             margin: auto;
//             cursor: pointer;
//             &.left {
//                 left: 12px;
//             }
//             &.right {
//                 right: 0;
//             }
//         }
//     }
// }