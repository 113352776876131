.footerfuso {
  color: rgba(244, 234, 234, 0.749);
  text-align: center;
  background-color: rgb(0, 0, 0);
  padding-top: 10px;
  margin-top: -10px;
}

.phoverfooter:hover {
  color: #07dff5 !important;
}