.gallery {
  /* width: 350px; */
  /* height: 250px important;
  display: flex;
  column-gap: 50px;
  column-count: 3;
  padding-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px; */
  background-color: #111f3b;
}

.wrapper {
  background-color: #111f3b;
  padding: 20px;
}

.title {
  margin-top: 0px;
  font-size: small;
  color: white;
  text-align: left;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.percent {
  font-size: small;
  text-align: left;
}

.ribbon {
  background-color:#435270;
  color: white !important;
  font-weight: "500";
  height: 40px;
  padding-left: 10px;
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
}

.slick-next:before {
  color:rgb(84, 83, 83) !important;
  font-size: 28px !important;
}

.slick-prev:before {
  color:rgb(84, 83, 83) !important;
  font-size: 28px !important;
  margin-right: 20px !important;
}

/* height: 170px !important; */

/* .slick-list {
  height: 250px !important;
} */

.showMore {
  border: none;
  background: none;
  background-color: rgb(78, 78, 78);
  font-size: small;
  border-radius: 5px;
  color: white;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 250px !important;
  padding: 20px;
  background: #111f3b;
  margin: 5px 0;
}

.tileWrap {
  width: 100%;
  max-width: 1200px !important;
  margin: 2px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-right: 20px;
  background-color: #111f3b;
}

.viewAll {
  border: none;
  border-radius: 5px;
  background-color: rgb(5, 85, 134);
  color: white;
  font-family: "Brush Script MT";
}

.searchOptionView {
  display: inline-block;
  margin-left: 10%;
}

::placeholder {
  color: black;
  opacity: 0.5;
  font-size: small;
}

.MuiPaginationItem-page.Mui-selected {
  color: #fff !important;
  background-color: #1797ce !important;
}

.MuiPaginationItem-root {
  /* color: rgb(72, 71, 71) !important; */
  color: black !important;
}

li {
  padding: 10px !important;
}

.paginationCntr {
  display: "flex" !important;
  flex-direction: "column" !important;
  justify-content: "center" !important;
  /* margin-left: 34%; */
}

.dropdown {
  position: relative;
}

.textfield {
  color: white !important;
  background-color: "#435270";
  font-size: 18px;
  font-weight: 500;
}

.cellStyle {
  margin: 0%;
  display: block;
  max-height: 2px;
  padding: 0%;
}

.MuiList-root {
  color: rgb(13, 74, 127);
}
