/* Popup style */

.popup-box2 {
  position: fixed;
  background-color: rgb(207, 241, 255, 0.01);
  width: 100%;
  top: 0;
  left: 0;
  height: 1500px;
  padding-top: 75px;
  z-index: 1000;
}

.box2 {
  position: relative;
  width: 14% !important;
  margin: 0 auto;

  height: 60 px;
  max-height: 60px;
  margin-top: 0;
  /* background-color: #111f3b; */
  border-radius: 1px;
  /* padding: 20px; */
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 0px;
  padding-right: 0px;
  overflow: auto;
  margin-left: 37.5%;
}

.close-icon1 {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(65% - 5px);
  top: calc(100vh - 86vh - 8px);
  background: rgb(204, 215, 219, 0.1);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 0px !important;
  top: 75px;
}
.css-177ic5c {
  border-radius: 0px !important;
  top: 75px;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 0px !important;
  top: 75px;
}
/* Popup style */
/* .popup-box {
  position: fixed;
  background-color: rgb(198, 231, 244);
  width: 75%;
  height: 2vh;
  top: 0;
  left: 0;
  margin-left: 0px;
  margin-top: 75px;
}

.box {
  position: relative;
  width: 75% !important;
  margin: 0 auto;

  height: 75px !important;
  max-height: 30vh;
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(55.5% - 30px);
  top: calc(100vh - 84vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
} 

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 0px !important;
  top: 75px;
}

.css-177ic5c {
  border-radius: 0px !important;
  top: 75px;
}
*/
