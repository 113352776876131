/* Popup style */
.vanish {
  display: none;
}

.popup-box1 {
  position: fixed;

  background-color: rgb(207, 241, 255, 0.01);

  width: 100%;
  top: 0;
  left: 0;
  height: 1500px;
  padding-top: 75px;

  z-index: 1000;
}

#box1 {
  position: relative;
  width: 16% !important;
  margin: 0 auto;
  margin-left: 24%;
  max-height: 110px;
  margin-top: 0;
  background-color: #111f3b;
  border-radius: 1px;
  padding: 10px;
  overflow: auto;
}

.fill-div {
  min-width: 100% !important;
  min-height: 100% !important;
  text-decoration: none;
  color: white;
}

.menuItem {
  text-decoration: none;
  color: white !important;
  background-color: #111f3b !important;
  font: 14px Helvetica !important;
  width: -webkit-fill-available;
}
.newmenuItem {
  text-decoration: none;
  color: white !important;
  background-color: #111f3b !important;
  font: 14px Helvetica !important;
  width: -webkit-fill-available;
  justify-content: left !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex;
  flex-direction: row !important;
}
.css-r8u8y9 {
  display: flex;
  flex-direction: row !important;
}
.MenuBar {
  background-color: "#111f3b !important";
  padding-top: "5px";
}
/* 
.menuItem.hover {
  background-color: #2d3d5f !important;
} */

.css-6hp17o-MuiList-root-MuiMenu-list {
  /* background-color: #111f3b !important; */
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.css-r8u8y9 {
  /* background-color: #111f3b !important; */
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
