.gallery {
  /* width: 350px; */
  height: 250px important;
  display: flex;
  column-gap: 50px;
  column-count: 3;
  padding-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  background-color: #111f3b;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  /* color: #f8f9fa; */
  color: none;
}
/* .MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{

} */
.MuiFormLabel-root {
  display: none;
}
.title {
  margin-top: 0px;
  font-size: small;
  color: white;
  text-align: left;
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.percent {
  font-size: small;
  text-align: left;
  height: 40px;
  color: white;
}

/* .ribbon{
  background-color: #00677f;
  width: 96%;
  border-radius: 3px;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 40%);
  padding: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2%;
} */

.announcement {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

/* .slick-list {
  height: 250px !important;
} */

.slick-next:before {
  color:rgb(84, 83, 83) !important;
  font-size: 28px !important;
}

.slick-prev:before {
  color:rgb(84, 83, 83) !important;
  font-size: 28px !important;
  margin-right: 20px !important;
}

.showMore {
  border: none;
  background: none;
  background-color: rgb(78, 78, 78);
  font-size: small;
  border-radius: 5px;
  color: white;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 250px !important;
  padding: 20px;
  background: #111f3b;
  margin: 5px 0;
}

.tileWrap {
  width: 100%;
  max-width: 1200px;
  margin: 2px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-right: 20px;
}

.viewAll {
  border: none;
  border-radius: 5px;
  background-color: rgb(5, 85, 134);
  color: white;
  font-family: "Brush Script MT";
}

.ribbon {
  color: white !important;
  /* font-weight: Normal;  */
  align-self: start;
  height: "10px" !important;
  opacity: 1 !important;
  background-color: #435270 !important;
  border-radius: 3px;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 40%);
  padding: 7px;
  display: flex;
  flex-direction: row;
  /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
  width: 96%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 2%;
  font-size: 18px;
  font-weight: 500;
}

.searchOptionView {
  display: inline-block;
  margin-left: 10%;
}

::placeholder {
  color: black;
  opacity: 0.5;
  font-size: small;
}

.MuiPaginationItem-page.Mui-selected {
  color: #fff !important;
  background-color: #1797ce !important;
}

.MuiPaginationItem-root {
  /* color: rgb(72, 71, 71) !important; */
  color: black !important;
}

.paginationCntr {
  display: "flex" !important;
  flex-direction: "column" !important;
  justify-content: "center" !important;
  /* margin-left: 36%; */
  /* margin-left: 38%; */
  align-items: center !important;
  /* padding: 1px; */
}
/* li {
  padding: 0px;
} */
.dropdown {
  /* padding: 10px !important; */
  position: relative;
}

.textfield {
  color: white !important;
  background-color: "#435270";
  font-size: 18px;
  font-weight: 500;
}

.cellStyle {
  margin: 0%;
  display: block;
  max-height: 2px;
  padding: 0%;
}

.MuiList-root {
  color: rgb(13, 74, 127);
}

.description {
  margin-top: 0px;
  font-size: small;
  color: white;
  text-align: left;
  margin-bottom: 0px;
}

.directlink {
  color: white;
  font-size: small;
  margin-top: 0px;
  text-align: right !important;
  font-style: italic;
}

.dropdown {
  background-color: #435270 !important;
  color: white !important;
  font-size: small;
  height: 24px;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 0px;
  display: inline;
}

.btn-sm {
  --bs-btn-padding-y: 0.1rem;
}

.autoCompleteTextBox {
  background-color: white;
  margin-left: 60px;
  border-radius: 5px;
}
.MuiInputBase-root {
  background-color: white;
  height: 28px;
  font-weight: 200;
  font-size: small;
}

.backButton {
  color: #fff;
  font-weight: 400;
  background: none;
  border: none;
  padding-left: 10px;
  margin-bottom: 0;
}
