.leftalign {
  margin-left: 76%;
  margin-top: 2%;
  position: fixed;
  /* border-color: rgba(246, 18, 18, 0.986); */
  /* margin-top: 10px; */
  /* background-color: rgba(255, 255, 255, 0.733); */
}

/* .buttoncolor {
  background-color: yellow;
} */
/* Found btn*/
.btn {
  text-align: left;
}

.divback {
  background-color: rgba(36, 36, 36, 0.94);
}
/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(17.5% - 10px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.footerfuso {
  color: rgb(244, 234, 234);
  text-align: center;
  background-color: black;
  margin-top: -60px;
}
/* comment */
