.listItem {
  width: 224px;
  height: 120px;
  background-color: var(--main-color);
  margin-right: 19px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  video {
    width: 100%;
    height: 140px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  // &:active {
  //     width: 325px;
  //     height: 300px;
  //     position: absolute;
  //     top: -150px;
  //     -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
  //     box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
  //     border-radius: 5px;
  //     .closeicon {
  //         float: right;
  //         background-color: white;
  //     }
  //     img {
  //         height: 140px;
  //     }
  //     .itemInfo {
  //         display: flex;
  //         flex-direction: column;
  //         padding: 5px;
  //         .icons {
  //             display: flex;
  //             margin-bottom: 10px;
  //             .icon {
  //                 border: 2px solid white;
  //                 padding: 5px;
  //                 border-radius: 50%;
  //                 margin-right: 10px;
  //                 font-size: 16px;
  //             }
  //             .likesCount {
  //                 padding: 3px;
  //                 border-radius: 50%;
  //                 margin-right: 5px;
  //                 font-size: 16px;
  //             }
  //         }
  //         .itemInfoTop {
  //             display: flex;
  //             align-items: center;
  //             margin-bottom: 10px;
  //             font-size: 14px;
  //             font-weight: 600;
  //             color: gray;
  //             .limit {
  //                 border: 1px solid gray;
  //                 padding: 1px 3px;
  //                 margin: 0 10px;
  //             }
  //             .views {
  //                 margin: 0 15px;
  //             }
  //         }
  //         .desc {
  //             font-size: 11px;
  //             margin-bottom: 7px;
  //         }
  //         .genre {
  //             font-size: 10px;
  //             color: lightgray;
  //         }
  //     }
  // }
}

.clickedclass {
  // width: 225px;
  // height: 120px;
  // background-color: var(--main-color);
  background-color: #464646;
  margin-right: 5px;
  overflow-x: var(--overflow-property);
  cursor: pointer;
  color: white;
  width: 275px;
  height: 300px;
  position: relative;
  //top: -72px;
  top: 0px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
  box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
  border-radius: 5px;
  .closeicon {
    float: right;
    // background-color: white;
  }
  img {
    width: 100%;
    // height: 100%;
    object-fit: cover;
    height: 140px;
  }
  .itemInfo {
    display: flex;
    flex-direction: column;
    padding: 5px;
    .icons {
      display: flex;
      margin-bottom: 10px;
      .icon {
        border: 2px solid white;
        padding: 5px;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 30px;
      }
      .ContinueWatching {
        padding: 3px;
      }
      .likesCount {
        padding: 3px;
        border-radius: 50%;
        margin-right: 5px;
        font-size: 16px;
      }
    }
    .itemInfoTop {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 600;
      color: gray;
      .limit {
        border: 1px solid gray;
        padding: 1px 3px;
        margin: 0 10px;
      }
      .views {
        margin: 0 15px;
      }
    }
    .desc {
      font-size: 11px;
      margin-bottom: 7px;
      .desc {
        font-size: 11px;
        margin-bottom: 7px;
        .labelDes {
          font-weight: 600;
        }
      }
    }
    .readMore {
      display: flex;
      flex-direction: row-reverse;
      font-size: 11px;
      color: deepskyblue;
    }
    .genre {
      font-size: 10px;
      color: lightgray;
    }
  }
}
