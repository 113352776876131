.ddlSubHeader {
  padding: 4px 8px 2px 8px !important;
  max-height: 28px !important;
}

.ddlListItem {
  padding: 2px 2px 2px 2px !important;
  margin-left: 6px !important;
}

.MuiListSubheader-root {
  line-height: 28px !important;
}
