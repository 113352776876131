:root {
    // --main-color: #0b0b0b;
    // --main-color: #464646;
    --main-color: #111f3b;
    // --main-color: #00677f;
    --overflow-property: hidden;
}

.bg-image {
  max-width: 100vw;
  background-image: url("./ELearning/Assets/Images/truck.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  backdrop-filter: blur(5px);
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

body{
  overflow-x: hidden;
}