.btn {
  background-color: #435270 !important;
}

.btn :hover {
  background-color: #435270 !important;
}

.caAlert {
  padding: 0px 0px 0px 0px !important;
  margin-bottom: 0px !important;
}

.alert-dismissible .btn-close {
  padding: 4px 4px 4px 4px !important;
}

.progressBar {
  max-height: 10px !important;
  width: 100%;
  height: 10px;
  margin-right: 5px;
  margin-top: 2px;
}
