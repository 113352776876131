.watch {
  // width: 100vw;
  // height: 100vh;
  .back {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    color: rgb(249, 244, 244);
    cursor: pointer;
    z-index: 2;
  }
  .VideoPlayer {
    padding-bottom: 47.55% !important;
  }
}
