.grid-container {
  display: grid;
  /* grid-template-columns: auto auto auto; */
  background-color: #e8e7f2;
  padding: 10px;
  align-items: center;
}

.grid-item {
  padding: 12px;
  text-align: center;
}

.grid-item-type {
  padding: 12px;
  text-align: left;
}