.MyTabs {
    color: white;
    background-color: #111f3b;
    height: 75px;
    padding-top: 20px;
}

.MyTab {
    color: white !important;
    display: grid;
    column-count: 3;
}

.MyTab hover {
    color: blue !important;
}

.header {
    background-color: rgb(14, 81, 100);
    color: white important;
}

.subtab {
    color: white !important;
    font-weight: bold !important;
    align-self: start;
    height: "10px" !important;
    opacity: 1 !important;
    background-color: #435270 !important;
    border-radius: 3px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 40%);
    padding: 7px;
    margin-left: "20px" !important;
}

.subtabs {
    color: white !important;
    width: 190px !important;
    background-color: rgb(74, 70, 70) !important;
    height: 50px;
    padding-top: 20px;
    margin-left: 270px !important;
    text-align: center;
}

.imgBackground {
    width: "10%";
    background-image: url("../Assets/Images/truck.png");
    background-repeat: "no-repeat";
    background-size: "cover";
    height: "10vh";
    background-blend-mode: luminosity;
}

.userinfo {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    /* padding-left: 1%; */
    /* padding-top: 10px; */
    font-weight: 200;
    /* margin-bottom: 0%; */
    /* padding-right: 1%; */
    padding: 1% 1% 0 1%;
}

.link {
    text-decoration: none;
    color: white;
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    position: relative;
}

.underConstruction {
    color: white;
    text-align: center;
}

.ucBorder {
    background-color: black;
    height: 150px;
    width: 500px;
    margin-left: 25%;
    padding-top: 50px;
    margin-top: 15%;
    opacity: 0.6;
}

.language {
    background: white;
    display: flex;
    align-items: center;
    .Button {
        cursor: pointer;
        background-color: white;
        border: 1px solid white;
        color: #7b7070;
        margin-left: 20px;
        height: 40px;
        border-radius: 5px;
    }
}

.linkbutton {
    padding: 0px;
    justify-content: left !important;
    text-align: left;
}