.ribbon {
    color: white !important;
    /* font-weight: Normal;  */
    align-self: start;
    height: "10px" !important;
    opacity: 1 !important;
    background-color: #435270 !important;
    border-radius: 3px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 40%);
    padding: 7px;
    display: flex;
    flex-direction: row;
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    width: 96%;
    margin-top: 5%;
    margin-bottom: 20px;
    margin-left: 2%;
    font-size: 18px;
    font-weight: 500;
  }
