.dvAcessDeniedDescription {
  font: X-Large Corpos;
  font-weight: bold;
  text-align: center;
  margin-top: 10%;
  color: red;
}

.divAccessDenied {
  margin-left: 0 !important;
  position: absolute;
  left: 1%;
  width: 98%;
  height: 82%;
}
