.language {
  background: white;
  display: flex;
  align-items: center;
  .Button {
    cursor: pointer;
    background-color: white;
    border: 1px solid white;
    color: #7b7070;
    margin-left: 20px;
    height: 40px;
    border-radius: 5px;
  }
}

.drawerImageRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: "10px";
}
