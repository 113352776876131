.CardHeader {
  background-color: #435270 !important;
  color: white !important;
}

.offerLogo {
  height: 150px;
  width: 150px;
}

.ProgressBarDetails {
  margin-top: 0.6% !important;
  margin-left: -6%;
}

.formHelperText {
  margin-left: 0px !important;
}

.bg-PROGRESSBAR_COLOR {
  background-color: rgba(0, 103, 127, 0.9) !important;
}

.css-qg6j3g.TableTreeContent-content {
  white-space: wrap;
}

.scroll-container::-webkit-scrollbar {
  width: 5px; /*sets the thickness of vertical scroll  */
  height: 8px; /*sets the thickness of horizontal scroll */
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgb(99, 126, 244);
  // background-color: rgb(162, 176, 245);
  border-radius: 10px;
}
.scroll-container::-webkit-scrollbar-track {
  background-color: rgb(250, 247, 247);
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  display: block !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  display: block !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list,
.css-r8u8y9 {
  display: block !important;
}
@media only screen and (max-device-width: 480px) {
  .row {
    display: block !important;
  }
}

@media only screen and (device-width: 768px) {
  .right-content {
    margin-left: 60px !important;
  }
}
