.listCategory {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  // .seeAllCategory {
  //     color: white;
  //     font-size: 15px;
  //     font-weight: 500;
  //     float: right;
  //     margin-right: 13px;
  //     cursor: pointer;
  // }
  .listTitleHeader {
    background-color: #435270;
    //width: 250px;
    border-radius: 3px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 40%);
    padding: 7px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 28px;
    margin-right: 28px;
  }
  .listTitleCategory {
    color: white;
    font-size: 20px;
    font-weight: 500;
    // margin-left: 50px;
  }
  .titleCategory {
    margin-top: 0px;
    font-size: small;
    color: white;
    text-align: left;
    font-weight: bold;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .wrapperCategory {
    position: relative;
    .containerCategory {
      margin-left: 30px;
      margin-right: 30px;
      display: flex;
      // width: max-content;
      margin-top: 10px;
      //flex-wrap: wrap;
      padding: 20px;
      // justify-content: space-between;
      // transform: translate(0px);
      // transition: all 1s ease;
      // margin-left: 50px;
      // display: flex;
      /* width: max-content; */
      // margin-top: 10px;
      // transform: translate(0px);
      // transition: all 1s ease;
      // flex-wrap: wrap;
      // flex-direction: row;
      // justify-content: center;
      .titleCategory {
        color: white;
        margin-left: 10px;
        margin-top: 7px;
        font-size: 13px;
        margin-bottom: 33px;
      }
    }
    // .sliderArrowCategory {
    //     width: 50px;
    //     height: 100%;
    //     background-color: rgba(22, 22, 22, 0.5);
    //     color: white;
    //     position: absolute;
    //     z-index: 99;
    //     top: 0;
    //     bottom: 0;
    //     margin: auto;
    //     cursor: pointer;
    //     &.left {
    //         left: 0;
    //     }
    //     &.right {
    //         right: 0;
    //     }
    // }
  }
  .more {
    color: white;
    font-size: 15px;
    font-weight: 500;
    float: right;
    margin-right: 13px;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 18px;
  }
}

.tileWrap {
  width: 100%;
  max-width: 1200px !important;
  margin: 2px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-right: 20px;
  background-color: #111f3b;
}
