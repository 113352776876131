.row {
  font-size: small;
  padding-bottom: 10px;
}

.quiz {
  text-align: center;
  background-color: gray;
  min-height: 100% !important;
  min-width: 100%;
  background-size: cover;
  height: 100vh;
}

.questioncard {
  background-color: #d6dade;
  padding: 16px;
  width: 80%;
  height: auto;
  margin: 0 auto;
  border-radius: 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  color: black;
  font-size: 14px;
  text-align: left;
}

.questioncard ul {
  list-style: none;
}

.questioncard li {
  background-color: rgb(197, 195, 195);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 8px;
  padding: none;
}

.questiontext {
  font-size: 20px;
  color: rgb(2, 2, 75);
}

.final-results {
  background-color: gray;
  padding: 16px;
  width: 50%;
  height: auto;
  margin: 0 auto;
  border-radius: 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  color: white;
}

.headercard {
  background-color: #435270 !important;
  color: white !important;
  padding: 5px;
  font-size: 18px;
}

.nxtButton {
  background-color: red;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  margin: 5px;
}

.container,
.container-fluid {
  width: 80% !important;
  padding-top: 2%;
}

.btnAssessment {
  background-color: #435270;
}

.arrowBtn{
  padding: 0 !important;
}
