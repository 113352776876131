.navbar {
  color: white;
  font-size: 14px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: linear-gradient(
    to top,
    transparent 0%,
    rgb(0, 0, 0, 0.3) 50%
  );
  &.scrolled {
    // background-color: var(--main-color);
    background-color: #40445a;
  }
  .container1 {
    display: flex;
    align-items: center;
    margin-left: 65px;
    height: 60px;
    justify-content: space-between;
    padding: 0px 20px;
    .left {
      display: flex;
      align-items: center;
      margin-left: -95px;
      img {
        height: 39px !important;
        cursor: pointer;
      }
      span {
        margin-left: 36px;
        cursor: pointer;
      }
      .MuiInputBase-root {
        height: 40px !important;
      }
      .category {
        //position: absolute;
        //top: 80px;
        //left: 50px;
        //font-size: 30px;
        //font-weight: 500;
        color: white;
        display: flex;
        align-items: center;
        //margin-left: 200px;
        margin-left: 555px;
        select {
          cursor: pointer;
          background-color: white;
          border: 1px solid white;
          color: #7b7070;
          margin-left: 58px;
          padding: 5px;
          height: 40px;
          border-radius: 5px;
        }
      }
      // .language {
      //     //position: absolute;
      //     //top: 80px;
      //     //left: 50px;
      //     //font-size: 30px;
      //     //font-weight: 500;
      //     color: white;
      //     display: flex;
      //     align-items: center;
      //     Button {
      //         cursor: pointer;
      //         background-color: #302f2f;
      //         border: 1px solid white;
      //         color: white;
      //         margin-left: 20px;
      //         padding: 5px;
      //         height: 40px;
      //     }
      // }
      .autoCompleteTextBox {
        background-color: white;
        margin-left: 60px;
        border-radius: 5px;
        // height: 36px;
        // border-radius: 7px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      margin-left: 700px;
      // .autoCompleteTextBox {
      //     background-color: white;
      //     // height: 36px;
      //     // border-radius: 7px;
      // }
      span {
        cursor: pointer;
      }
      .icon {
        margin: 0px 10px;
        cursor: pointer;
      }
      .profile {
        .options {
          display: none;
          // background-color: var(--main-color);
          background-color: #302f2f;
          border-radius: 5px;
          span {
            padding: 10px;
            cursor: pointer;
          }
        }
        &:hover {
          .options {
            display: flex;
            flex-direction: column;
            position: absolute;
          }
        }
      }
      .language {
        //position: absolute;
        //top: 80px;
        //left: 50px;
        //font-size: 30px;
        //font-weight: 500;
        color: white;
        display: flex;
        align-items: center;
        margin-left: 150px;
        //max-height: 40px;
        //border-radius: 2px;
        //border-radius: 3px;
        Button {
          cursor: pointer;
          background-color: white;
          border: 1px solid white;
          color: #7b7070;
          margin-left: 20px;
          height: 40px;
          //border-radius: 3px;
        }
      }
    }
  }
}
